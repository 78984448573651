import React from "react";
import { Box, Divider, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../common/network/network";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../common/style/styles";
import { useEffect } from "react";
// 청구회사 정보
const BillingCompanyInfo = () => {
  const [company, setCompany] = useState({});
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = () => {
    console.log(company);
    if (Object.keys(company).length < 7) {
      alert("정보를 모두 입력해주세요.");
      return;
    }

    setProcessing(true);

    if (mode === "create") {
      postDataFromServer("/billing-company", company)
        .then((response) => {
          alert("정보 저장이 완료되었습니다.");
          navigate("/billing-company");
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      putDataFromServer(`/billing-company/${id}`, company)
        .then((response) => {
          alert("정보 저장이 완료되었습니다.");
          navigate("/billing-company");
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  useEffect(() => {
    if (mode !== "create") {
      getDataFromServer(`/billing-company/${id}`).then((response) => {
        setCompany({ ...response.data });
      });
    }
  }, []);

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            회사명 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.name}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="name"
              value={company.name}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            회사주소 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.address}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="address"
              value={company.address}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            사업자번호 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.business_number}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="business_number"
              value={company.business_number}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            은행명 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.bank}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="bank"
              value={company.bank}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            계좌번호 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.account_number}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="account_number"
              value={company.account_number}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            스위프트 :
          </Typography>{" "}
          {mode === "show" ? (
            <Typography>{company.swift}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="swift"
              value={company.swift}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            대금지급조건 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.payment_condition}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="payment_condition"
              value={company.payment_condition}
              onChange={handleInputChange}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography width="100px" margin="0 1rem" textAlign="left">
            국가 :
          </Typography>
          {mode === "show" ? (
            <Typography>{company.country}</Typography>
          ) : (
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="country"
              value={company.country}
              onChange={handleInputChange}
            />
          )}
        </Box>
      </Box>
      {mode !== "show" ? (
        <Box display="flex">
          <Box
            onClick={handleSubmit}
            width="300px"
            sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem"
          >
            <Typography variant="subtitle1" color="white">
              정보 저장
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex">
          <Box
            onClick={() => {
              navigate(`/billing-company/edit/${id}`);
            }}
            width="300px"
            sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem"
          >
            <Typography variant="subtitle1" color="white">
              정보 수정
            </Typography>
          </Box>
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default BillingCompanyInfo;
