import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createCustomsDeclarationExcel } from "../common/js/common";
import {
  getDataFromServer,
  postDataFromServer,
} from "../common/network/network";
import DataTable from "../components/data_table";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../common/style/styles";

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "출고국가", en: "shipment_country", width: "70px", type: "text" },
  { ko: "출항일", en: "shipment_date", width: "70px", type: "text" },
  { ko: "운송장개수", en: "count", width: "50px", type: "text" },
  { ko: "다운로드", en: "euro", width: "50px", type: "button" },
];

// 세관신고서 작성 페이지
const CustomsDuty = () => {
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [image, setImage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [euro, setEuro] = useState(null);

  useEffect(() => {
    setProcessing(true);
    getDataFromServer("/customs-duty")
      .then((resonse) => {
        const newRows = resonse.result.map((data, index) => {
          data.id = index + 1;
          return data;
        });
        setRows([...newRows]);
      })
      .catch((error) => {})
      .finally(() => {
        setProcessing(false);
      });

    fetch(
      "https://mpick.s3.ap-northeast-2.amazonaws.com/mpick/erp/invoice_sign.png"
    )
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((buffer) => {
        // console.log(buffer);
        setImage(buffer);
      });
  }, []);

  const handleChange = (event) => {
    setEuro(event.target.value);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 저장된 s3 파일 다운로드
  const handleClickButton = (id) => {
    // setProcessing(true);

    // 선택한 데이터 목록 가져오기
    const selectedData = rows.find((row) => id === row.id);

    const url = `https://mpick.s3.ap-northeast-2.amazonaws.com/mpick/erp/${selectedData["shipment_date"]}+Customs+Invoice.xlsx`;
    window.open(url);
  };

  const handleDownlaod = () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }

    if (!euro) {
      alert("변환율을 입력해주세요.");
      return;
    }

    setProcessing(true);

    // 선택한 데이터 목록 가져오기
    const selectedData = rows.filter((row) => selectedIDs.includes(row.id));

    // 시작 인덱스번호 결정하기 (2024-02-13부터 적용, 년도별&페이지별 부여)
    const selectedDate = selectedData[0].shipment_date;
    let idx = 0;
    if (new Date(selectedDate) < new Date("2024-02-13")) {
      idx = 0;
    } else {
      idx = 1;
    }

    for (const row of rows) {
      const tempDate = new Date(row.shipment_date);
      const basisDate = new Date(selectedDate);
      if (
        tempDate < basisDate &&
        tempDate.getFullYear === basisDate.getFullYear &&
        tempDate >= new Date("2024-02-13")
      ) {
        // 2024-03-17 세관신고서가 26개인데 페이지 분리가 안되서 넘버링 하나씩 당기기
        if (row.shipment_date === "2024-03-17") {
          idx += 1;
        } else {
          idx +=
            row.count % 25 !== 0
              ? parseInt(row.count / 25) + 1
              : parseInt(row.count / 25);
        }
      }
    }

    getDataFromServer(
      `/customs-duty/select?data=${JSON.stringify(selectedData)}`
    )
      .then(async (response) => {
        // 목록별로 for문 돌면서 변환
        for (const result of response.result) {
          // 달러/변환율로 가격 수정 , 소수점 둘째자리에서 반올림
          const newData = result.map((value) => {
            value.price = (parseFloat(value.price) / euro).toFixed(2);
            value.total_price = (parseFloat(value.total_price) / euro).toFixed(
              2
            );
            return value;
          });

          const downloadresult = await createCustomsDeclarationExcel(
            newData,
            image,
            `${result[0].shipment_date} Customs Invoice`,
            idx
          );

          // console.log(downloadresult);

          const formData = new FormData();
          formData.append(
            "data",
            JSON.stringify(rows.filter((data) => selectedIDs.includes(data.id)))
          );
          formData.append("excel", downloadresult);
          formData.append(
            "file_name",
            `${result[0].shipment_date} Customs Invoice.xlsx`
          );
          formData.append("euro", euro);

          // 다운로드 후 변환율 저장
          if (downloadresult) {
            postDataFromServer("/customs-duty", formData)
              .then((response) => {})
              .catch((error) => {});
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <DataTable
        columns={columns}
        selectedIDs={selectedIDs}
        rows={rows}
        handleClickCheckBox={handleClickCheckBox}
        // handleInputInfoChange={handleInputInfoChange}
        handleClickButton={handleClickButton}
      />
      <Box display="flex">
        <Box
          sx={{ minWidth: 300 }}
          display="flex"
          alignItems="center"
          margin="0.5rem 0"
        >
          <Typography margin="0 1rem" variant="subtitle2">
            달러-&gt;유로 변환율
          </Typography>
          <TextField
            variant="outlined"
            name="변환율"
            value={euro}
            onChange={handleChange}
          />
        </Box>

        <Box
          onClick={handleDownlaod}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            세관신고서 생성
          </Typography>
        </Box>
      </Box>

      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default CustomsDuty;
