import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Box,
  Divider,
  Typography,
  InputLabel,
  Select,
} from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../common/style/styles";
import {
  postDataFromServer,
  putDataFromServer,
} from "../common/network/network";
import DataTable from "../components/data_table";

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "출항일", en: "shipment_date", width: "50px", type: "text" },
  { ko: "주문일", en: "order_date", width: "50px", type: "text" },
  { ko: "운송장번호", en: "shipment_number", width: "70px", type: "text" },
  { ko: "수취인", en: "recipient_name", width: "50px", type: "text" },
  { ko: "HSCODE", en: "hscode", width: "50px", type: "input" },
  { ko: "상품명", en: "item_name", width: "150px", type: "input" },
  { ko: "상품가격(달러)", en: "price", width: "50px", type: "input" },
  { ko: "수량", en: "quantity", width: "50px", type: "input" },
  { ko: "상품총가격(달러)", en: "total_price", width: "50px", type: "input" },
  {
    ko: "판매사이트",
    en: "site_url",
    width: "70px",
    type: "input",
  },
];

// 송장데이터 업로드 및 보기
const ShipmentUpload = () => {
  const [country, setCountry] = useState(null);
  const [company, setCompany] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [rows, setRows] = useState([]);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 국가 변경
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  // 배송사 변경
  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };

  // 엑셀 첨부
  const handleExcelFile = (event) => {
    if (!event.target.files) {
      return;
    }
    setExcelFile([...event.target.files]);
  };

  // 입력 정보 변경
  const handleInputInfoChange = (event, id) => {
    const { name, value } = event.target;

    const newRows = rows.map((row) => {
      if (id === row.id) {
        return { ...row, [name]: value };
      } else {
        return row;
      }
    });

    setRows([...newRows]);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 엑셀 업로드
  const handleSubmit = () => {
    if (!excelFile) {
      alert("파일을 첨부해주세요.");
      return;
    }

    if (!country) {
      alert("국가를 선택해주세요.");
      return;
    }

    if (!company) {
      alert("배송사를 선택해주세요.");
      return;
    }

    if (processing) {
      return;
    }

    setProcessing(true);
    const formData = new FormData();

    let fileNames = [];

    for (const file of excelFile) {
      formData.append(file.name, file);
      fileNames.push(file.name);
    }

    formData.append("company", company);
    formData.append("country", country);
    formData.append("fileNames", JSON.stringify(fileNames));

    postDataFromServer("/invoice", formData)
      .then((response) => {
        console.log(response.result);
        setRows([...response.result]);
        alert("송장데이터 업로드가 완료되었습니다.");
      })
      .catch((error) => {
        alert("업로드에 실패했습니다. 엑셀을 확인해주세요.");
        // window.location.reload();
      })
      .finally(() => {
        setProcessing(false);
        setExcelFile(null);
        setCompany(null);
        setCountry(null);
      });
  };

  //   데이터 수정
  const handleUpdateData = () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }

    setProcessing(true);
    // 선택된 행 중 input 데이터만 필터링
    const filteredData = rows.filter((row) => {
      const hasId = selectedIDs.filter((value) => value == row.id).length;
      if (hasId) {
        return row;
      }
    });

    let newData = [];
    for (const row of filteredData) {
      let tempData = {};
      for (const column of columns) {
        if (column.type === "input" || column.en === "id") {
          tempData[column.en] = row[column.en]
            ? row[column.en]
            : column.default
            ? column.default
            : "";
        }
      }
      newData.push(tempData);
    }

    putDataFromServer("/invoice", { data: newData })
      .then((response) => {
        alert("수정이 완료되었습니다.");
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Box margin="2rem 1rem">
      <Box display="flex">
        {/* 배송사선택 드롭다운 */}
        <Box
          sx={{ minWidth: 150 }}
          display="flex"
          alignItems="center"
          margin="1rem 0"
        >
          <Typography variant="subtitle2" margin="0 1rem">
            배송사 :
          </Typography>

          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>배송사</InputLabel>

            <Select value={company} onChange={handleChangeCompany}>
              <MenuItem value="ACI">ACI</MenuItem>
              <MenuItem value="판토스">판토스</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* 국가선택 드롭다운 */}
        <Box
          sx={{ minWidth: 150 }}
          display="flex"
          alignItems="center"
          margin="1rem 0"
        >
          <Typography variant="subtitle2" margin="0 1rem">
            출고 국가 :
          </Typography>

          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>출고 국가</InputLabel>

            <Select value={country} onChange={handleChangeCountry}>
              <MenuItem value="스페인">스페인</MenuItem>
              <MenuItem value="프랑스">프랑스</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box>
        {/* 송장 업로드 */}
        <Box margin="16px 8px" width="80%">
          <Divider />
          <Box margin="1rem 0" padding="0.5rem" border={"1px dashed"}>
            <label htmlFor="excel">
              <Typography margin="0 4px">
                업로드할 파일을 선택해주세요. (.xlsx 확장자만 첨부 가능)
              </Typography>
            </label>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="excel"
              id="excel"
              multiple
              onChange={handleExcelFile}
            />
          </Box>
          {excelFile && (
            <Box margin="1rem 0" padding="0.5rem" border={"1px solid"}>
              <Typography>선택된 파일 : </Typography>
              {excelFile.map((excel) => (
                <Typography>{excel.name}</Typography>
              ))}
            </Box>
          )}
          <Box
            onClick={handleSubmit}
            sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem 0"
          >
            <Typography variant="subtitle1" color="white">
              업로드
            </Typography>
          </Box>
        </Box>

        {/* 데이터테이블 */}
        <DataTable
          columns={columns}
          rows={rows}
          selectedIDs={selectedIDs}
          handleClickCheckBox={handleClickCheckBox}
          handleInputInfoChange={handleInputInfoChange}
        />
      </Box>

      <Box
        onClick={handleUpdateData}
        width="300px"
        sx={{ backgroundColor: "darkblue", cursor: "pointer" }}
        padding="0.5rem 0"
        margin="1rem"
      >
        <Typography variant="subtitle1" color="white">
          데이터수정
        </Typography>
      </Box>
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default ShipmentUpload;
