import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme, Pages } from "./common/style/styles";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import OrderUpload from "./layout/order_upload";
import Header from "./components/header";
import MenuList from "./components/menu_list";
import { Box } from "@mui/system";
import Search from "./layout/search";
import Login from "./layout/login";
import ShipmentUpload from "./layout/shipment_upload";
import { IsAdminState } from "./common/state/state";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { getDataFromServer } from "./common/network/network";
import User from "./layout/user";
import CustomsDuty from "./layout/customs_duty";
import Join from "./layout/join";
import StoreList from "./layout/store_list";
import Store from "./layout/store";
import Invoice from "./layout/invoice";
import BillingCompany from "./layout/billing_company";
import BillingCompanyInfo from "./layout/billing_company_info";
import ClientCompany from "./layout/client_company";
import ClientCompanyInfo from "./layout/client_company_info";

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useRecoilState(IsAdminState);

  useEffect(() => {
    !pathname.includes("login") &&
      getDataFromServer("/login/confirm")
        .then((response) => {
          setIsAdmin(response.is_login);
        })
        .catch(() => {
          alert("로그인 후 사용해주세요.");
          navigate("/login");
        });
  }, [pathname]);

  return (
    <div className="App">
      <ThemeProvider theme={mainTheme}>
        {pathname.includes("login") ? null : isAdmin ? <Header /> : null}

        <Pages>
          <Box display="flex">
            {pathname.includes("login") ? null : isAdmin ? (
              <MenuList flex="1" />
            ) : null}
            <Box flex="9" sx={{ overflow: "auto" }}>
              <Routes>
                {/* 존재하지 않는 페이지 리디렉션 */}
                {/* <Route path="*" exact={true} element={<Main />} /> */}
                {/* 서비스 페이지 */}

                <Route path="/login" exact={true} element={<Login />} />
                {isAdmin && (
                  <>
                    <Route path="/" exact={true} element={<OrderUpload />} />
                    <Route
                      path="/upload"
                      exact={true}
                      element={<OrderUpload />}
                    />
                    <Route path="/orders" exact={true} element={<Search />} />
                    <Route
                      path="/shipment"
                      exact={true}
                      element={<ShipmentUpload />}
                    />
                    <Route path="/users" exact={true} element={<User />} />
                    <Route
                      path="/customs-duty"
                      exact={true}
                      element={<CustomsDuty />}
                    />
                    <Route path="/join" exact={true} element={<Join />} />
                    <Route path="/store" exact={true} element={<StoreList />} />
                    <Route
                      path="/store/:mode/:id"
                      exact={true}
                      element={<Store />}
                    />
                    <Route path="/invoice" exact={true} element={<Invoice />} />
                    <Route
                      path="/billing-company"
                      exact={true}
                      element={<BillingCompany />}
                    />
                    <Route
                      path="/billing-company/:mode/:id"
                      exact={true}
                      element={<BillingCompanyInfo />}
                    />
                    <Route
                      path="/client"
                      exact={true}
                      element={<ClientCompany />}
                    />
                    <Route
                      path="/client/:mode/:id"
                      exact={true}
                      element={<ClientCompanyInfo />}
                    />
                  </>
                )}
              </Routes>
            </Box>
          </Box>
        </Pages>
      </ThemeProvider>
    </div>
  );
}

export default App;
