import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState(pathname.replace("/", ""));

  useEffect(() => {
    setSelectedMenu(pathname.replace("/", ""));
  }, [pathname]);

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "users"}
            onClick={() => {
              navigate("users");
            }}
          >
            <ListItemText primary="사용자 관리" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "upload"}
            onClick={() => {
              navigate("upload");
            }}
          >
            <ListItemText primary="주문데이터 업로드" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "orders"}
            onClick={() => {
              navigate("orders");
            }}
          >
            <ListItemText primary="주문데이터 조회" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "shipment"}
            onClick={() => {
              navigate("shipment");
            }}
          >
            <ListItemText primary="송장데이터 업로드(ACI)" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "customs-duty"}
            onClick={() => {
              navigate("customs-duty");
            }}
          >
            <ListItemText primary="세관신고서 작성" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "invoice"}
            onClick={() => {
              navigate("invoice");
            }}
          >
            <ListItemText primary="고객사 인보이스 생성" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "store"}
            onClick={() => {
              navigate("store");
            }}
          >
            <ListItemText primary="스토어 정보관리" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "billing-company"}
            onClick={() => {
              navigate("billing-company");
            }}
          >
            <ListItemText primary="청구회사 관리" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={selectedMenu === "client"}
            onClick={() => {
              navigate("client");
            }}
          >
            <ListItemText primary="고객사 관리" />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default MenuList;
