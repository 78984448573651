import axios from "axios";

const pathname = window.location.href;

const baseURL = pathname.includes("localhost")
  ? process.env.REACT_APP_LOCAL_URL
  : pathname.includes("test.million-pick")
  ? process.env.REACT_APP_TEST_URL
  : process.env.REACT_APP_SERVICE_URL;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = baseURL;

export async function getDataFromServer(url) {
  const response = await axios.get(baseURL + url);

  try {
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function postDataFromServer(url, data) {
  const response = await axios.post(baseURL + url, data);

  try {
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function deleteDataFromServer(url) {
  const response = await axios.delete(baseURL + url);

  try {
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function putDataFromServer(url, data) {
  const response = await axios.put(baseURL + url, data);

  try {
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
