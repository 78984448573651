import * as XLSX from "xlsx";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import jimp from "jimp";
import testimg from "../../media_assets/image/invoice_sign.png";
import { getDataFromServer } from "../network/network";
import AWS from "aws-sdk";

// 날짜를 양식에 맞게 변환
export function convertDate(data, type) {
  const year = data.getFullYear();
  const month = data.getMonth();
  const date = data.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (type === 1) {
    // 년-월-일

    return `${year}-${month + 1 >= 10 ? month + 1 : "0" + (month + 1)}-${
      date >= 10 ? date : "0" + date
    }`;
  } else if (type === 2) {
    // 일 월(영어) 년

    return `${date} ${monthNames[month]} ${year}`;
  } else if (type === 3) {
    // 년월일

    return `${year}${month + 1 >= 10 ? month + 1 : "0" + (month + 1)}${
      date >= 10 ? date : "0" + date
    }`;
  } else if (type === 4) {
    //년.월.일

    return `${year}.${month + 1 >= 10 ? month + 1 : "0" + (month + 1)}.${
      date >= 10 ? date : "0" + date
    }`;
  } else if (type === 5) {
    // 일-월-년
    return `${date}-${monthNames[month]}-${year}`;
  }
}

// 송장 엑셀 변환
export function createShipmentExcel(columns, rows, fileName) {
  const newColumn = columns.map((data) => data.ko);
  let newRows = [];
  for (const row of rows) {
    let tempData = {};
    for (const column of columns) {
      tempData[column.en] = row[column.en] ? row[column.en] : "";
    }
    newRows.push(tempData);
  }

  const worksheet = XLSX.utils.json_to_sheet(newRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  XLSX.utils.sheet_add_aoa(worksheet, [newColumn], {
    origin: "A1",
  });

  XLSX.writeFile(workbook, `${fileName}_${Date.now()}.xlsx`);
}

// 세관 신고 엑셀 변환
export async function createCustomsDeclarationExcel(data, img, fileName, idx) {
  const skyBuleFillStyle = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "8EA9DB",
    },
  };

  const greyFillStyle = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "D9D9D9",
    },
  };

  const allBorderStyle = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };

  const notBottomBorderStyle = {
    top: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  const notTopBorderStyle = {
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  const leftBottomBorderStyle = {
    bottom: { style: "thin" },
    left: { style: "thin" },
  };

  const rightBottomBorderStyle = {
    bottom: { style: "thin" },
    right: { style: "thin" },
  };

  const leftBodrderStyle = {
    left: { style: "thin" },
  };

  const rightBorderStyle = {
    right: { style: "thin" },
  };

  const leftRightBorderStyle = {
    right: { style: "thin" },
    left: { style: "thin" },
  };

  const titleFontStyle = {
    name: "Verdana",
    color: { argb: "ffffff" },
    family: 2,
    size: 14,
    bold: true,
  };

  const centerAlignmentStyle = {
    horizontal: "center",
    vertical: "middle",
  };

  const lightBlueFillStyle = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "D9E1F2",
    },
  };

  const subtitleFontStyle = {
    name: "Verdana",
    family: 2,
    size: 7,
  };

  const leftAlignmentStyle = {
    horizontal: "left",
    vertical: "middle",
  };

  const itemAlignmentStyle = {
    horizontal: "left",
    vertical: "middle",
    shrinkToFit: true,
  };

  const yellowFillStyle = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "FFFF00",
    },
  };

  const bodyFontStyle = {
    name: "Verdana",
    family: 2,
    size: 8,
  };

  const rightAlignmentStyle = {
    horizontal: "right",
    vertical: "middle",
  };

  const boldSubtitleFontStyle = {
    name: "Verdana",
    family: 2,
    size: 7,
    bold: true,
  };

  const boldBodyFontStyle = {
    name: "Verdana",
    family: 2,
    size: 7,
    bold: true,
  };

  const boldRedFontStyle = {
    name: "Verdana",
    family: 2,
    size: 8,
    bold: true,
    color: { argb: "FF0000" },
  };

  const boldBigFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
    bold: true,
  };

  const bigFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
  };

  try {
    const workbook = new Excel.Workbook();

    let main = [];
    const mainColumn = {
      cells: [
        {
          name: "A16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "B16:C16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "E16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "F16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "G16",
          fill: lightBlueFillStyle,
          border: allBorderStyle,
          font: boldSubtitleFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [
        "No.",
        "DESCRIPTION OF GOODS",
        ,
        "HS CODE",
        "QTY",
        "UNIT VALUE",
        "TOTAL VALUE",
      ],
    };

    let pageData = [];
    let item_num = 0;
    let newIndex = 16;
    for (const [index, invoiceData] of data.entries()) {
      // 25개 기준으로 나누기
      if (index % 25 === 0 || index === 0 || index === data.length - 1) {
        // console.log(index, pageData);
        if (pageData.length > 0) {
          if (index === data.length - 1) {
            if (pageData.length % 26 === 0) {
              main.push(pageData);
              pageData = [];
              item_num = 0;
              newIndex = 16;
              pageData.push(mainColumn);
            }

            pageData.push({
              cells: [
                {
                  name: `A${newIndex + 1}`,
                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: centerAlignmentStyle,
                },
                {
                  name: `B${newIndex + 1}:C${newIndex + 1}`,

                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: itemAlignmentStyle,
                },
                {
                  name: `D${newIndex + 1}`,
                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: centerAlignmentStyle,
                },
                {
                  name: `E${newIndex + 1}`,
                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: centerAlignmentStyle,
                },
                {
                  name: `F${newIndex + 1}`,
                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: rightAlignmentStyle,
                  numFmt: "0.00€",
                },
                {
                  name: `G${newIndex + 1}`,
                  border: allBorderStyle,
                  font: boldBodyFontStyle,
                  alignment: rightAlignmentStyle,
                  numFmt: "0.00€",
                },
              ],
              values: [
                item_num + 1,
                `ORDER#${index + 1}${invoiceData.item_name + 1}`,
                ,
                invoiceData.hscode,
                invoiceData.quantity,
                parseFloat(invoiceData.price),
                parseFloat(invoiceData.total_price),
              ],
            });
          }
          main.push(pageData);
          pageData = [];
          item_num = 0;
          newIndex = 16;
        }

        pageData.push(mainColumn);
      }

      item_num += 1;
      newIndex += 1;

      pageData.push({
        cells: [
          {
            name: `A${newIndex}`,
            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: centerAlignmentStyle,
          },
          {
            name: `B${newIndex}:C${newIndex}`,

            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: itemAlignmentStyle,
          },
          {
            name: `D${newIndex}`,
            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: centerAlignmentStyle,
          },
          {
            name: `E${newIndex}`,
            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: centerAlignmentStyle,
          },
          {
            name: `F${newIndex}`,
            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: rightAlignmentStyle,
            numFmt: "0.00€",
          },
          {
            name: `G${newIndex}`,
            border: allBorderStyle,
            font: boldBodyFontStyle,
            alignment: rightAlignmentStyle,
            numFmt: "0.00€",
          },
        ],
        values: [
          item_num,
          `ORDER#${index + 1}${invoiceData.item_name}`,
          ,
          invoiceData.hscode,
          invoiceData.quantity,
          parseFloat(invoiceData.price),
          parseFloat(invoiceData.total_price),
        ],
      });
    }

    for (let i = 0; i < main.length; i++) {
      workbook.addWorksheet(`${i + 1}`, {
        properties: { defaultRowHeight: 18 },
        pageSetup: { fitToPage: true, fitToWidth: 1 },
      });
    }

    const image = workbook.addImage({
      buffer: img,
      extension: "jpeg",
    });

    // 시트별로 나눠서 데이터 생성
    workbook.eachSheet(function (worksheet, sheetId) {
      const mainData = main[sheetId - 1];
      const date = data[0]["shipment_date"];

      worksheet.pageSetup.printArea = "A1:G49";

      worksheet.columns = [
        { header: "", key: "A", width: 18 },
        { header: "", key: "B", width: 26 },
        { header: "", key: "C", width: 41 },
        { header: "", key: "D", width: 19 },
        { header: "", key: "E", width: 14 },
        { header: "", key: "F", width: 19 },
        { header: "", key: "G", width: 20 },
      ];

      const header = [
        {
          cells: [
            {
              name: "A1:G1",
              fill: skyBuleFillStyle,
              alignment: centerAlignmentStyle,
              font: titleFontStyle,
              border: allBorderStyle,
            },
          ],
          values: ["COMMERCIAL INVOICE"],
        },
        {
          cells: [
            {
              name: "A2:B2",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
              outerWidth: 18,
            },
            {
              name: "C2:D2",
              fill: lightBlueFillStyle,
              alignment: centerAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "E2:G2",
              fill: lightBlueFillStyle,
              alignment: centerAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
          ],
          values: ["DATE OF EXPORT", , "INVOICE NO.", , "AWB NO."],
        },
        {
          cells: [
            {
              name: "A3:B3",
              fill: yellowFillStyle,
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notTopBorderStyle,
            },
            {
              name: "C3",
              fill: yellowFillStyle,
              alignment: rightAlignmentStyle,
              font: bodyFontStyle,
              border: leftBottomBorderStyle,
            },
            {
              name: "D3",
              fill: yellowFillStyle,
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: rightBottomBorderStyle,
            },
            { name: "E3:G3", border: notTopBorderStyle },
          ],
          values: [
            convertDate(new Date(date), 2),
            ,
            idx === 0
              ? convertDate(new Date(date), 3)
              : `MPICK/${new Date(date).getFullYear()}/${
                  new Array(5 - String(idx).length + 1).join("0") +
                  String(idx + sheetId - 1)
                }`,
            idx === 0 ? "ISP" : "",
          ],
        },
        {
          cells: [
            {
              name: "A4:C4",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "D4:G4",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
          ],
          values: ["SHIPPER / EXPORTER", , , "CONSIGNEE"],
        },
        {
          cells: [
            { name: "A5", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "B5:C5", font: bodyFontStyle, border: rightBorderStyle },
            { name: "D5", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "E5:G5", font: bodyFontStyle, border: rightBorderStyle },
          ],
          values: [
            "Name",
            "MILLION PICK S.L.",
            ,
            "Name",
            "ACI WORLDWIDE EXPRESS CORP",
          ],
        },
        {
          cells: [
            { name: "A6", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "B6:C6", font: bodyFontStyle, border: rightBorderStyle },
            { name: "D6", font: bodyFontStyle, border: leftAlignmentStyle },
            { name: "E6:G6", font: bodyFontStyle, border: rightBorderStyle },
          ],
          values: [
            "Address",
            "C/ RAIMUNDO FERNANDEZ VILLAVERDE 44, ",
            ,
            "Address",
            "ACI B/D 121, Nambusunhwan-ro 19-gil",
          ],
        },
        {
          cells: [
            { name: "A7", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "B7:C7", font: bodyFontStyle, border: rightBorderStyle },
            { name: "D7", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "E7:G7", font: bodyFontStyle, border: rightBorderStyle },
          ],
          values: [
            ,
            "Escalera 1 Planta 6 Puerta 2, 28003, MADRID",
            ,
            ,
            ,
            "Gangseo-gu, Seoul, Korea",
          ],
        },
        {
          cells: [
            { name: "A8", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "B8:C8", font: bodyFontStyle, border: rightBorderStyle },
            { name: "D8", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "E8:G8", font: bodyFontStyle, border: rightBorderStyle },
          ],
          values: ["EORI NO.", "ESB44816353"],
        },
        {
          cells: [
            { name: "A9", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "B9:C9", font: bodyFontStyle, border: rightBorderStyle },
            { name: "D9", font: bodyFontStyle, border: leftBodrderStyle },
            { name: "E9:G9", font: bodyFontStyle, border: rightBorderStyle },
          ],
          values: ["Phone", "34 654246287", , "Phone", "+82-2-2663-3300"],
        },
        {
          cells: [
            {
              name: "A10:B10",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "C10",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "D10:G10",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
          ],
          values: [
            "PORT OF LOADING",
            ,
            "COUNTRY OF DESTINATION",

            "Notify (IF DIFFERENT THAN CONSIGNEE)",
          ],
        },
        {
          cells: [
            {
              name: "A11:B11",
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notTopBorderStyle,
            },
            {
              name: "C11",
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notTopBorderStyle,
            },
            { name: "D11", font: bodyFontStyle },
            { name: "E11:G11", border: rightBorderStyle },
          ],
          values: ["MAD / SPAIN", , "ICN / KOREA", "Name"],
        },
        {
          cells: [
            {
              name: "A12:B12",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: subtitleFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "C12",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notBottomBorderStyle,
            },
            { name: "D12", font: bodyFontStyle, alignment: leftAlignmentStyle },
            { name: "E12:G12", border: rightBorderStyle },
          ],
          values: ["SAILING ON OR ABOUT", , "REMARK / FLIGHT NO.", "Address"],
        },
        {
          cells: [
            {
              name: "A13:B13",
              font: bodyFontStyle,
              border: notTopBorderStyle,
              alignment: leftAlignmentStyle,
            },
            {
              name: "C13",
              font: bodyFontStyle,
              border: notTopBorderStyle,
              alignment: leftAlignmentStyle,
            },
            { name: "D13" },
            { name: "E13:G13", border: rightBorderStyle },
          ],
          values: [convertDate(new Date(date), 4), , "KE0914"],
        },
        {
          cells: [
            {
              name: "A14:B14",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notBottomBorderStyle,
            },
            {
              name: "C14",
              fill: lightBlueFillStyle,
              alignment: leftAlignmentStyle,
              font: bodyFontStyle,
              border: notBottomBorderStyle,
            },
            { name: "D14" },
            { name: "E14:G14", border: rightBorderStyle },
          ],
          values: ["NO & DATE OF L/C", , "L/C ISSUING BANK"],
        },
        {
          cells: [
            { name: "A15:B15", border: notTopBorderStyle },
            { name: "C15", border: notTopBorderStyle },
            { name: "D15", font: bodyFontStyle, alignment: leftAlignmentStyle },
            { name: "E15:G15", border: rightBorderStyle },
          ],
          values: [, , , , "Phone"],
        },
      ];

      const sumTotalPrice = mainData
        .map((invoiceData, index) => {
          if (index !== 0) {
            return parseFloat(invoiceData.values[6]).toFixed(2);
          }
        })
        .filter((value) => value)
        .reduce((accumulator, currentValue) => {
          return parseFloat(accumulator) + parseFloat(currentValue);
        }, 0)
        .toFixed(2);

      const footerRowNum = 15 + mainData.length + 1;

      const footer = [
        {
          cells: [
            {
              name: `A${footerRowNum}:B${footerRowNum}`,
              fill: yellowFillStyle,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
            {
              name: `C${footerRowNum}`,
              fill: yellowFillStyle,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
            {
              name: `D${footerRowNum}:D${footerRowNum + 3}`,
              font: boldBigFontStyle,
              alignment: rightAlignmentStyle,
              border: allBorderStyle,
            },
          ],
          values: [
            "Number of items",
            ,
            mainData.length - 1,
            "TOTAL",
            parseFloat(sumTotalPrice),
          ],
        },
        {
          cells: [
            {
              name: `A${footerRowNum + 1}:B${footerRowNum + 1}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
            {
              name: `C${footerRowNum + 1}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
          ],
          values: ["Net weight", , "kg"],
        },
        {
          cells: [
            {
              name: `A${footerRowNum + 2}:B${footerRowNum + 2}`,
              fill: yellowFillStyle,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
            {
              name: `C${footerRowNum + 2}`,
              fill: yellowFillStyle,
              border: allBorderStyle,
            },
          ],
          values: ["Total weight"],
        },
        {
          cells: [
            {
              name: `A${footerRowNum + 3}:B${footerRowNum + 3}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
            {
              name: `C${footerRowNum + 3}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: allBorderStyle,
            },
          ],
          values: ["FREIGHT TERM", , "CPT"],
        },

        {
          cells: [
            {
              name: `A${footerRowNum + 4}:G${footerRowNum + 4}`,
              font: boldRedFontStyle,
              border: leftRightBorderStyle,
            },
          ],
          values: [
            "*** I DECLARE THAT THE CONTENT OF THIS INVOICE IS TRUE AND CORRECT ***",
          ],
        },
        {
          cells: [
            { name: `A${footerRowNum + 5}`, border: leftBodrderStyle },
            { name: `G${footerRowNum + 5}`, border: rightBorderStyle },
          ],
          values: [],
        },
        {
          cells: [
            {
              name: `A${footerRowNum + 6}`,
              font: bodyFontStyle,
              border: leftBodrderStyle,
            },
            {
              name: `G${footerRowNum + 6}`,
              border: rightBorderStyle,
            },
          ],
          values: ["SIGNATURE:"],
        },
        {
          cells: [
            {
              name: `A${footerRowNum + 7}:G${footerRowNum + 7}`,
              border: notTopBorderStyle,
            },
          ],
          values: [],
        },
      ];

      // header
      worksheet.insertRows(
        1,
        header.map((data) => {
          return data.values;
        })
      );

      for (const data of header) {
        for (const cell of data.cells) {
          // cell 중 : 는 셀병합
          if (cell.name.includes(":")) {
            try {
              worksheet.mergeCells(cell.name);
            } catch (error) {
              console.log(error);
            }
          }

          // 행별로 스타일 적용
          for (const [key, value] of Object.entries(cell)) {
            if (key !== "name") {
              worksheet.getCell(cell.name)[key] = value;
            }
          }
        }
      }

      // main
      worksheet.insertRows(
        16,
        mainData.map((data) => {
          return data.values;
        })
      );

      for (const data of mainData) {
        // console.log(data.cells, data.values);

        for (const cell of data.cells) {
          // cell 중 : 는 셀병합
          if (cell.name.includes(":")) {
            // console.log(cell);
            try {
              worksheet.mergeCells(cell.name);
            } catch (error) {
              console.log(cell.name, error);
            }
          }

          // 행별로 스타일 적용
          for (const [key, value] of Object.entries(cell)) {
            if (key !== "name") {
              worksheet.getCell(cell.name)[key] = value;
            }
          }
        }
      }

      // footer

      worksheet.addImage(image, `B${footerRowNum + 6}:B${footerRowNum + 7}`);

      worksheet.insertRows(
        15 + mainData.length + 1,
        footer.map((data) => {
          return data.values;
        })
      );

      worksheet.mergeCells(`E${footerRowNum}`, `G${footerRowNum + 3}`);
      worksheet.getCell(`E${footerRowNum}`, `G${footerRowNum + 3}`).numFmt =
        "0.00€";
      worksheet.getCell(`E${footerRowNum}`, `G${footerRowNum + 3}`).font =
        boldBigFontStyle;
      worksheet.getCell(`E${footerRowNum}`, `G${footerRowNum + 3}`).alignment =
        rightAlignmentStyle;
      worksheet.getCell(`E${footerRowNum}`, `G${footerRowNum + 3}`).border =
        allBorderStyle;

      for (const data of footer) {
        // console.log(data.cells, data.values);

        for (const cell of data.cells) {
          // cell 중 : 는 셀병합
          if (cell.name.includes(":")) {
            try {
              worksheet.mergeCells(cell.name);
            } catch (error) {
              // console.log(sheetId, cell.name, error);
            }
          }

          // 행별로 스타일 적용
          for (const [key, value] of Object.entries(cell)) {
            if (key !== "name") {
              worksheet.getCell(cell.name)[key] = value;
            }
          }
        }
      }
    });

    const resumeWorksheet = workbook.addWorksheet("RESUME", {
      properties: { defaultRowHeight: 18 },
      pageSetup: { fitToPage: true, fitToWidth: 1 },
    });

    resumeWorksheet.columns = [
      { header: "", key: "A", width: 3 },
      { header: "", key: "B", width: 13 },
      { header: "", key: "C", width: 7 },
      { header: "", key: "D", width: 10 },
    ];

    const sumData = [
      {
        cells: [
          {
            name: "B2",
            fill: greyFillStyle,
            alignment: centerAlignmentStyle,
            font: boldBigFontStyle,
            border: allBorderStyle,
          },
          {
            name: "C2",
            fill: greyFillStyle,
            alignment: centerAlignmentStyle,
            font: boldBigFontStyle,
            border: allBorderStyle,
          },
          {
            name: "D2",
            fill: greyFillStyle,
            alignment: centerAlignmentStyle,
            font: boldBigFontStyle,
            border: allBorderStyle,
          },
        ],
        values: [, , "HS CODE", "count", "Euro"],
      },
    ];

    let tempData = {};

    for (const value of data) {
      if (tempData.hasOwnProperty(value.hscode)) {
        tempData[value.hscode].count += 1;
        tempData[value.hscode].euro.push(value.total_price);
      } else {
        tempData[value.hscode] = {
          count: 1,
          euro: [value.total_price],
        };
      }
    }

    for (let i = 3; i < 30; i++) {
      sumData.push({
        cells: [
          {
            name: `B${i}`,
            alignment: rightAlignmentStyle,
            font: bigFontStyle,
            border: allBorderStyle,
          },
          {
            name: `C${i}`,
            alignment: centerAlignmentStyle,
            font: bigFontStyle,
            border: allBorderStyle,
          },
          {
            name: `D${i}`,
            alignment: rightAlignmentStyle,
            font: bigFontStyle,
            border: allBorderStyle,
            numFmt: "0.00€",
          },
        ],
      });
    }

    for (const [index, [key, value]] of Object.entries(
      Object.entries(tempData)
    )) {
      const newIndex = parseInt(index) + 1;
      sumData[newIndex]["values"] = [
        ,
        ,
        key,
        value.count,
        value.euro
          .reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue);
          }, 0)
          .toFixed(2),
      ];
    }

    sumData.push({
      cells: [
        {
          name: `B30`,
          alignment: centerAlignmentStyle,
          font: boldBigFontStyle,
          border: allBorderStyle,
        },
        {
          name: `C30`,
          alignment: centerAlignmentStyle,
          font: boldBigFontStyle,
          border: allBorderStyle,
        },
        {
          name: `D30`,
          alignment: centerAlignmentStyle,
          font: boldBigFontStyle,
          border: allBorderStyle,
          numFmt: "0.00€",
        },
      ],
      values: [
        ,
        ,
        "Total general",
        data.length,
        data
          .reduce((accumulator, currentValue) => {
            return (
              parseFloat(accumulator) + parseFloat(currentValue.total_price)
            );
          }, 0)
          .toFixed(2),
      ],
    });

    resumeWorksheet.insertRows(
      2,
      sumData.map((data) => {
        return data.values;
      })
    );

    for (const data of sumData) {
      // console.log(data.cells, data.values);
      for (const cell of data.cells) {
        // 행별로 스타일 적용
        for (const [key, value] of Object.entries(cell)) {
          if (key !== "name") {
            resumeWorksheet.getCell(cell.name)[key] = value;
          }
        }
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName}_${Date.now()}.xlsx`);

    return new Blob([buffer]);
  } catch (error) {
    return false;
  }
}

// 인보이스 엑셀 변환
export async function createInvoiceExcel(
  itemData,
  invoice_date,
  invoice_number,
  img,
  billingCompanyInfo,
  clientCompanyInfo,
  shipmentDateList,
  consigneeName,
  consigneeAddress,
  fileName
) {
  // 입력 > 셀 merge > 스타일 적용

  // 1. 출항일자에 해당하는 세관신고서 정보 가져오기
  // 2. 엑셀 만드는것처럼 데이터 폼 변환하기
  // 3. 청구회사에 해당하는 스토어의 정보만 가져오기
  // 4. 엑셀로 변환하기

  const centerAlignmentStyle = {
    horizontal: "center",
    vertical: "middle",
    shrinkToFit: true,
  };

  const grayFillStyle = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "A6A6A6",
    },
  };

  const boldBigFontStyle = {
    name: "Verdana",
    family: 2,
    size: 18,
    bold: true,
  };

  const redFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
    color: {
      argb: "990033",
    },
    bold: true,
  };

  const grayFontStyle = {
    name: "Verdana",
    family: 2,
    size: 12,
    color: {
      argb: "808080",
    },
  };

  const subtitleFontStyle = {
    name: "Verdana",
    family: 2,
    size: 12,
  };

  const boldBodyFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
    bold: true,
  };

  const bodyFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
  };

  const blueFontStyle = {
    name: "Verdana",
    family: 2,
    size: 10,
    color: {
      argb: "4472C4",
    },
  };

  const grayBorderStyle = {
    top: {
      style: "thin",
      color: {
        argb: "BDBDBD",
      },
    },
    left: {
      style: "thin",
      color: {
        argb: "BDBDBD",
      },
    },
    bottom: {
      style: "thin",
      color: {
        argb: "BDBDBD",
      },
    },
    right: {
      style: "thin",
      color: {
        argb: "BDBDBD",
      },
    },
  };

  const notBottomBorderStyle = {
    top: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  const BottomBorderStyle = {
    bottom: { style: "thin" },
  };

  const leftRightBorderStyle = {
    right: { style: "thin" },
    left: { style: "thin" },
  };

  const notTopBorderStyle = {
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  // console.log(
  //   itemData,
  //   billingCompanyInfo,
  //   clientCompanyInfo,
  //   shipmentDateList,
  //   fileName
  // );

  //날짜마다 세관신고 엑셀 만드는것처럼 데이터 폼 변환하기
  let main = [];
  for (const data of itemData) {
    for (const [index, invoiceData] of data.entries()) {
      // 청구회사에 해당하는 스토어의 정보만 가져오기
      if (clientCompanyInfo.url_list.includes(invoiceData.site_url)) {
        // 세관신고서 인보이스 번호 가져오기
        const selectedDate = invoiceData.shipment_date;
        let idx = 0;
        if (new Date(selectedDate) < new Date("2024-02-13")) {
          idx = 0;
        } else {
          idx = 1;
        }

        for (const row of shipmentDateList) {
          const tempDate = new Date(row.shipment_date);
          const basisDate = new Date(selectedDate);
          if (
            tempDate < basisDate &&
            tempDate.getFullYear === basisDate.getFullYear &&
            tempDate >= new Date("2024-02-13")
          ) {
            // 2024-03-17 세관신고서가 26개인데 페이지 분리가 안되서 넘버링 하나씩 당기기
            if (row.shipment_date === "2024-03-17") {
              idx += 1;
            } else {
              idx +=
                row.count % 25 !== 0
                  ? parseInt(row.count / 25) + 1
                  : parseInt(row.count / 25);
            }
          }
        }

        main.push({
          cells: [
            {
              name: `A${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
            },
            {
              name: `B${14 + main.length}:C${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
            },
            {
              name: `D${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
              numFmt: "0.00€",
            },
            {
              name: `E${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
            },
            {
              name: `F${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
              numFmt: "0%",
            },
            {
              name: `G${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
              numFmt: "0.00€",
            },
            {
              name: `H${14 + main.length}`,
              font: bodyFontStyle,
              alignment: centerAlignmentStyle,
              border: grayBorderStyle,
            },
          ],
          values: [
            invoiceData.shipment_date,
            `ORDER#${index + 1}${invoiceData.item_name}`,
            ,
            parseFloat(
              (parseFloat(invoiceData.price) / invoiceData.euro).toFixed(2)
            ),

            invoiceData.quantity,
            0,
            parseFloat(
              (parseFloat(invoiceData.total_price) / invoiceData.euro).toFixed(
                2
              )
            ),
            idx === 0
              ? `${convertDate(new Date(invoiceData.shipment_date), 3)} ISP`
              : `MPICK/${new Date(invoiceData.shipment_date).getFullYear()}/${
                  new Array(5 - String(idx).length + 1).join("0") +
                  String(
                    idx +
                      (invoiceData.shipment_date === "2024-03-17"
                        ? 0
                        : parseInt((index + 1) / 26))
                  )
                }`,
          ],
        });
      }
    }
  }

  const workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet("Sheet1", {
    properties: { defaultRowHeight: 18 },
    pageSetup: { fitToPage: true, fitToWidth: 1, fitToHeight: 100 },
    headerFooter: {
      oddFooter: `&L&B${billingCompanyInfo.name} / ${billingCompanyInfo.business_number} \n${billingCompanyInfo.address} \n${billingCompanyInfo.bank} / ${billingCompanyInfo.swift} / ${billingCompanyInfo.account_number} / ${billingCompanyInfo.payment_condition} &RPage &P `,
    },
  });

  worksheet.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.5,
    bottom: 1.5,
    header: 0.5,
    footer: 0.75,
  };

  worksheet.columns = [
    { header: "", key: "A", width: 11 },
    { header: "", key: "B", width: 10 },
    { header: "", key: "C", width: 30 },
    { header: "", key: "D", width: 7 },
    { header: "", key: "E", width: 5 },
    { header: "", key: "F", width: 5 },
    { header: "", key: "G", width: 9 },
    { header: "", key: "G", width: 12 },
  ];

  const image = workbook.addImage({
    buffer: img,
    extension: "jpeg",
  });

  const row1 = worksheet.getRow(1);
  row1.height = 30;

  worksheet.addImage(image, {
    tl: { col: 2.9, row: 0.2 },
    br: { col: 4, row: 1 },
  });

  worksheet.insertRows(3, [["FACTURA COMPLETA"]]);

  worksheet.mergeCells("A1:H1");
  worksheet.mergeCells("A3:H3");
  worksheet.getCell("A3", "H3").font = boldBigFontStyle;
  worksheet.getCell("A3", "H3").alignment = centerAlignmentStyle;

  // 인보이스넘버 Número Factura:
  worksheet.insertRows(5, [["Número Factura:", , invoice_number]]);
  worksheet.mergeCells("A5:B5");
  worksheet.mergeCells("C5:D5");
  worksheet.getCell("A5:B5").font = grayFontStyle;
  worksheet.getCell("C5:D5").font = subtitleFontStyle;
  worksheet.getCell("A5:B5").alignment = centerAlignmentStyle;
  worksheet.getCell("C5:D5").alignment = centerAlignmentStyle;
  worksheet.getCell("C5:D5").border = BottomBorderStyle;

  // 인보이스 발행일 Fecha factura:
  worksheet.insertRows(6, [
    ["Fecha factura:", , convertDate(new Date(invoice_date), 5)],
  ]);
  worksheet.mergeCells("A6:B6");
  worksheet.mergeCells("C6:D6");
  worksheet.getCell("A6:B6").font = grayFontStyle;
  worksheet.getCell("C6:D6").font = subtitleFontStyle;
  worksheet.getCell("A6:B6").alignment = centerAlignmentStyle;
  worksheet.getCell("C6:D6").alignment = centerAlignmentStyle;
  worksheet.getCell("C6:D6").border = BottomBorderStyle;

  // 고객사 Bill To / Consignee Ship to
  const headerColumn = [
    {
      cells: [
        {
          name: "A8:C8",
          border: notBottomBorderStyle,
          font: blueFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D8:H8",
          border: notBottomBorderStyle,
          font: blueFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: ["Bill To", , , "Ship to"],
    },
    {
      cells: [
        {
          name: "A9:C9",
          border: leftRightBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D9:H9",
          border: leftRightBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [clientCompanyInfo.name, , , consigneeName],
    },
    {
      cells: [
        {
          name: "A10:C10",
          border: leftRightBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D10:H10",
          border: leftRightBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [clientCompanyInfo.address, , , consigneeAddress],
    },
    {
      cells: [
        {
          name: "A11:C11",
          border: notTopBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D11:H11",
          border: notTopBorderStyle,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [`VAT: ${clientCompanyInfo.business_number}`],
    },
  ];

  worksheet.insertRows(
    8,
    headerColumn.map((data) => {
      return data.values;
    })
  );

  for (const data of headerColumn) {
    // console.log(data.cells, data.values);

    for (const cell of data.cells) {
      // cell 중 : 는 셀병합
      if (cell.name.includes(":")) {
        // console.log(cell);
        try {
          worksheet.mergeCells(cell.name);
        } catch (error) {
          console.log(cell.name, error);
        }
      }

      // 행별로 스타일 적용
      for (const [key, value] of Object.entries(cell)) {
        if (key !== "name") {
          worksheet.getCell(cell.name)[key] = value;
        }
      }
    }
  }

  // 상품
  const itemColumn = [
    {
      cells: [
        {
          name: "A13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "B13:C13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "D13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "E13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "F13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "G13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: "H13",
          fill: grayFillStyle,
          border: grayBorderStyle,
          font: redFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [
        "Export Date",
        "Description",
        ,
        "Precio Neto",
        "Unid.",
        "Tipo",
        "Importe",
        "Ref.",
      ],
    },
  ];

  worksheet.insertRows(
    13,
    itemColumn.map((data) => {
      return data.values;
    })
  );

  for (const data of itemColumn) {
    // console.log(data.cells, data.values);

    for (const cell of data.cells) {
      // cell 중 : 는 셀병합
      if (cell.name.includes(":")) {
        // console.log(cell);
        try {
          worksheet.mergeCells(cell.name);
        } catch (error) {
          console.log(cell.name, error);
        }
      }

      // 행별로 스타일 적용
      for (const [key, value] of Object.entries(cell)) {
        if (key !== "name") {
          worksheet.getCell(cell.name)[key] = value;
        }
      }
    }
  }

  worksheet.insertRows(
    14,
    main.map((data) => {
      return data.values;
    })
  );

  for (const data of main) {
    // console.log(data.cells, data.values);

    for (const cell of data.cells) {
      // cell 중 : 는 셀병합
      if (cell.name.includes(":")) {
        // console.log(cell);
        try {
          worksheet.mergeCells(cell.name);
        } catch (error) {
          console.log(cell.name, error);
        }
      }

      // 행별로 스타일 적용
      for (const [key, value] of Object.entries(cell)) {
        if (key !== "name") {
          worksheet.getCell(cell.name)[key] = value;
        }
      }
    }
  }

  // 총합
  const sumTotalPrice = main
    .map((invoiceData, index) => {
      return parseFloat(invoiceData.values[6]).toFixed(2);
    })
    .filter((value) => value)
    .reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue);
    }, 0)
    .toFixed(2);

  const totalColumn = [
    {
      cells: [
        {
          name: `D${main.length + 15}:F${main.length + 15}`,

          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: `G${main.length + 15}:H${main.length + 15}`,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
          numFmt: "0.00€",
        },
      ],
      values: [, , , , "Total sin IVA", , , parseFloat(sumTotalPrice)],
    },
    {
      cells: [
        {
          name: `D${main.length + 16}:F${main.length + 16}`,

          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
        {
          name: `G${main.length + 16}:H${main.length + 16}`,
          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
          numFmt: "0.00€",
        },
      ],
      values: [, , , , "Total IVA", , , 0],
    },
    {
      cells: [
        {
          name: `A${main.length + 17}`,

          font: bodyFontStyle,
          alignment: centerAlignmentStyle,
        },
      ],
      values: [],
    },
    {
      cells: [
        {
          name: `D${main.length + 18}:F${main.length + 18}`,

          font: boldBodyFontStyle,
          alignment: centerAlignmentStyle,
          border: BottomBorderStyle,
        },
        {
          name: `G${main.length + 18}:H${main.length + 18}`,
          font: boldBodyFontStyle,
          alignment: centerAlignmentStyle,
          border: BottomBorderStyle,
          numFmt: "0.00€",
        },
      ],
      values: [, , , , "Total Factura", , , parseFloat(sumTotalPrice)],
    },
  ];

  worksheet.insertRows(
    main.length + 15,
    totalColumn.map((data) => {
      return data.values;
    })
  );

  for (const data of totalColumn) {
    // console.log(data.cells, data.values);

    for (const cell of data.cells) {
      // cell 중 : 는 셀병합
      if (cell.name.includes(":")) {
        // console.log(cell);
        try {
          worksheet.mergeCells(cell.name);
        } catch (error) {
          console.log(cell.name, error);
        }
      }

      // 행별로 스타일 적용
      for (const [key, value] of Object.entries(cell)) {
        if (key !== "name") {
          worksheet.getCell(cell.name)[key] = value;
        }
      }
    }
  }

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
}
