import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const useStyles = makeStyles({});

export const primary = "#CA0900";
export const secondary = "#333333";
export const info = "#4F4F4F";
export const borderColor = "#E0E0E0";
export const lightcoral = "#ffdfdf";
export const lightgrey = "#F9F9F9";
export const grey = "#BDBDBD";
export const grey2 = "#585858";
export const grey3 = "#828282";
export const grey4 = "#F2F2F2";
export const grey5 = "#FBFBFB";
export const grey6 = "#D9D9D9";
export const blue = "#2F80ED";

// primary : 레드, secondary: 회색, info : 연한 회색
/*
  h2 - 30pt : 40px
  h3 - 27pt : 36px
  h4 - 24pt : 32px
  h5 - 21pt : 28px
  h6 - 18pt : 24px
  subtitle1 - 15pt : 20px
  subtitle2 - 12pt : 16px
  body1 - 10.5pt : 14px
  body2 - 9pt : 12px
  caption - 7.5pt : 10px
*/
export const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#CA0900",
    },
    secondary: {
      main: "#333333",
    },
    info: {
      main: "#4F4F4F",
    },
    grey: {
      main: "#BDBDBD",
    },
  },
  typography: {
    h2: { fontSize: "30pt" },
    h3: { fontSize: "27pt" },
    h4: { fontSize: "24pt" },
    h5: { fontSize: "21pt" },
    h6: { fontSize: "18pt" },
    subtitle1: { fontSize: "15pt" },
    subtitle2: { fontSize: "12pt" },
    body1: { fontSize: "10.5pt" },
    body2: { fontSize: "9pt" },
    caption: { fontSize: "7.5pt" },
  },
});

// 반응형 사이즈 계산
const calcWidthPercent = (span) => {
  if (!span) return;

  const width = (span / 12) * 100;
  return width;
};

export const BREAK_POINT_MOBILE = 768;
export const BREAK_POINT_TABLET = 992;
export const BREAK_POINT_PC = 1200;

// 반응형 사이즈에 따라 얼마나 넓이를 쓸건지 계산
export const Column = styled.div`
  width: ${({ xs }) => (xs ? `${calcWidthPercent(xs)}%` : `100%`)};

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}px) {
    width: ${({ sm }) => sm && `${calcWidthPercent(sm)}%`};
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}px) {
    width: ${({ md }) => md && `${calcWidthPercent(md)}%`};
  }
  @media only screen and (min-width: ${BREAK_POINT_PC}px) {
    width: ${({ lg }) => lg && `${calcWidthPercent(lg)}%`};
  }

  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: ${({ admin }) => (admin ? "1200px" : "375px")};
`;

// 페이지마다 header, tab bar 로 인해 가려지는 부분 margin으로 나타내기
export const Pages = styled.div`
  padding-top: 105px;
  padding-bottom: 60px;
  width: 100%;
`;

// <Link> 컴포넌트에서 밑줄 제거
export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  color: black;
`;

export const bottomModalStyle = {
  position: "absolute",
  bottom: "0",
  width: "100%",
  bgcolor: "background.paper",
  // backgroundColor: "rgba(0, 0, 0, 0.1)",
};

export const CenterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  overflow: "scroll",
  // backgroundColor: "rgba(0, 0, 0, 0.1)",
};
