import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../common/network/network";

const Store = () => {
  const navigate = useNavigate();
  const { mode, id } = useParams();
  const [storeInfo, setStoreInfo] = useState({});
  const [companyList, setCompanyList] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStoreInfo({ ...storeInfo, [name]: value });
  };

  const handleSubmit = () => {
    if (mode === "create") {
      postDataFromServer("/store", storeInfo)
        .then((response) => {
          alert("스토어 정보가 생성되었습니다.");
          navigate("/store");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      putDataFromServer(`/store/${id}`, storeInfo)
        .then((response) => {
          alert("스토어 정보가 수정되었습니다.");
          navigate("/store");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getDataFromServer(`/store/${id}`).then((response) => {
      setStoreInfo({ ...response.data });
    });

    if (mode !== "show") {
      getDataFromServer("/client").then((response) => {
        setCompanyList([...response.data]);
      });
    }
  }, [mode]);

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          스토어명 :
        </Typography>
        {mode === "show" ? (
          <Typography>{storeInfo.name}</Typography>
        ) : (
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="name"
            value={storeInfo.name}
            onChange={handleInputChange}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          판매채널 :
        </Typography>
        {mode === "show" ? (
          <Typography>{storeInfo.channel}</Typography>
        ) : (
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="channel"
            value={storeInfo.channel}
            onChange={handleInputChange}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          스토어 url :
        </Typography>
        {mode === "show" ? (
          <Typography>{storeInfo.url}</Typography>
        ) : (
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="url"
            value={storeInfo.url}
            onChange={handleInputChange}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          고객사 :
        </Typography>
        {mode === "show" ? (
          <Typography>{storeInfo.client_company_name}</Typography>
        ) : (
          <FormControl sx={{ width: "50%" }}>
            <InputLabel>고객사</InputLabel>

            <Select
              name="client_company_id"
              value={
                storeInfo.client_company_id ? storeInfo.client_company_id : null
              }
              onChange={handleInputChange}
            >
              {companyList.map((company) => (
                <MenuItem value={company.id}>{company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {mode !== "show" ? (
        <Box
          onClick={handleSubmit}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="2rem 1rem "
        >
          <Typography variant="subtitle1" color="white">
            스토어 정보 저장
          </Typography>
        </Box>
      ) : (
        <Box display="flex">
          <Box
            onClick={() => {
              navigate(`/store/edit/${id}`);
            }}
            width="300px"
            sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem"
          >
            <Typography variant="subtitle1" color="white">
              정보 수정
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Store;
