import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromServer } from "../common/network/network";
import DataTable from "../components/data_table";

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "회사명", en: "name", width: "70px", type: "text" },
  { ko: "국가", en: "country", width: "70px", type: "text" },
  { ko: "사업자번호", en: "business_number", width: "100px", type: "text" },
  { ko: "보기", en: "show", width: "50px", type: "page" },
  { ko: "수정", en: "edit", width: "50px", type: "page" },
];

// 청구회사 목록
const BillingCompany = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);

  useEffect(() => {
    getDataFromServer("/billing-company").then((response) => {
      setRows([...response.data]);
    });
  }, []);

  const handleClickButton = (mode, id) => {
    navigate(`/billing-company/${mode}/${id}`);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <DataTable
        columns={columns}
        selectedIDs={selectedIDs}
        rows={rows}
        handleClickCheckBox={handleClickCheckBox}
        // handleInputInfoChange={handleInputInfoChange}
        handleClickButton={handleClickButton}
      />
      <Box display="flex">
        <Box
          onClick={() => {
            navigate("/billing-company/create/-1");
          }}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            청구회사 추가
          </Typography>
        </Box>
        <Box
          //   onClick={handleDelete}
          width="300px"
          sx={{ backgroundColor: "red", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            청구회사 삭제
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BillingCompany;
