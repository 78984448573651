import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertDate, createShipmentExcel } from "../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../common/network/network";
import { primary } from "../common/style/styles";
import { TailSpin } from "react-loader-spinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataTable from "../components/data_table";

const regPhone = /([0-9]{3,4})/;

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "업로드일", en: "created_at", width: "70px", type: "text" },
  { ko: "스토어명", en: "store_name", width: "70px", type: "text" },
  { ko: "출고국가", en: "country", width: "50px", type: "text" },
  { ko: "배송사", en: "deliver_company", width: "50px", type: "text" },
  { ko: "주문번호", en: "order_uid", width: "70px", type: "text" },
  { ko: "수취인명", en: "recipient_name", width: "50px", type: "input" },
  { ko: "상품명", en: "en_name", width: "400px", type: "input" },
  { ko: "수량", en: "quantity", width: "50px", type: "text" },
  { ko: "상품금액", en: "price", width: "50px", type: "text" },
  {
    ko: "개인통관고유번호",
    en: "customs_number",
    width: "50px",
    type: "text",
  },
  { ko: "전화번호", en: "phone_number", width: "100px", type: "text" },
  {
    ko: "정산예정금액",
    en: "settlement_amount",
    width: "70px",
    type: "text",
  },
  { ko: "HSCODE", en: "hscode", width: "50px", type: "input" },
  {
    ko: "부피무게",
    en: "volume_weight",
    width: "50px",
    type: "input",
    default: 1,
  },
  {
    ko: "실무게",
    en: "actual_weight",
    width: "50px",
    type: "input",
    default: 1,
  },
  {
    ko: "용도구분",
    en: "usage_classification",
    width: "50px",
    type: "input",
    default: 1,
  },
  {
    ko: "포장개수",
    en: "package_count",
    width: "50px",
    type: "input",
    default: 1,
  },
  { ko: "배송메세지", en: "delivery_message", width: "100px", type: "text" },
];

//주문데이터 조회
const Search = () => {
  const [rows, setRows] = useState([]);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);

  const [uploadDate, setUploadDate] = React.useState();
  const [orderUid, setOrderUid] = useState();
  const [recipientName, setRecipientName] = useState();

  useEffect(() => {
    setProcessing(true);
    getDataFromServer("/order?filtered=false")
      .then((response) => {
        const newResult = response.result.map((data) => {
          data.created_at = convertDate(new Date(data.created_at), 1);
          data.deliver_company = data.country === "스페인" ? "ACI" : "판토스";
          data.en_name = data.en_name
            ? data.en_name
            : `${data.brand ? data.brand.replace(/[가-힣]+/, "").trim() : ""} ${
                data.item_name
              } ${
                data.option
                  ? data.option
                      .replace("컬러 :", "")
                      .replace("사이즈 :", "")
                      .replace(" / ", "")
                  : ""
              }`;
          return data;
        });
        console.log(newResult);
        setRows(newResult);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, []);

  // 입력 정보 변경
  const handleInputInfoChange = (event, id) => {
    const { name, value } = event.target;

    const newRows = rows.map((row) => {
      if (id === row.id) {
        return { ...row, [name]: value };
      } else {
        return row;
      }
    });

    setRows([...newRows]);
  };

  const handleChangeOrderUid = (event) => {
    const { name, value } = event.target;
    setOrderUid(value);
  };

  const handleChangeRecipientName = (event) => {
    const { name, value } = event.target;
    setRecipientName(value);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 조건에 따라 정보 검색
  const handleSearchFilteredData = () => {
    if (!uploadDate && !recipientName && !orderUid) {
      alert("검색 조건을 입력해주세요.");
      return;
    }

    setProcessing(true);

    getDataFromServer(
      `/order?filtered=true&uploadDate=${
        uploadDate ? convertDate(new Date(uploadDate), 1) : null
      }&recipientName=${recipientName ? recipientName : null}&orderUid=${
        orderUid ? orderUid : null
      }`
    )
      .then((response) => {
        const newResult = response.result.map((data) => {
          data.created_at = convertDate(new Date(data.created_at), 1);
          data.deliver_company = data.country === "스페인" ? "ACI" : "판토스";
          data.en_name = data.en_name
            ? data.en_name
            : `${data.brand ? data.brand.replace(/[가-힣]+/, "").trim() : ""} ${
                data.item_name
              } ${
                data.option
                  ? data.option
                      .replace("컬러 :", "")
                      .replace("사이즈 :", "")
                      .replace(" / ", "")
                  : ""
              }`;
          return data;
        });

        setRows(newResult);
      })
      .catch((error) => {
        alert("검색에 실패했습니다.");
        return;
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  //   데이터 수정
  const handleUpdateData = () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }

    setProcessing(true);
    // 선택된 행 중 input 데이터만 필터링
    const filteredData = rows.filter((row) => {
      const hasId = selectedIDs.filter((value) => value == row.id).length;
      if (hasId) {
        return row;
      }
    });

    let newData = [];
    for (const row of filteredData) {
      let tempData = {};
      for (const column of columns) {
        if (column.type === "input" || column.en === "id") {
          tempData[column.en] = row[column.en]
            ? row[column.en]
            : column.default
            ? column.default
            : "";
        }
      }
      newData.push(tempData);
    }

    putDataFromServer("/order", { data: newData })
      .then((response) => {
        alert("수정이 완료되었습니다.");
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  // 데이터 삭제
  const handleDeleteData = () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }
    if (!window.confirm("선택한 데이터를 삭제하시겠습니까?")) {
      return;
    }

    setProcessing(true);

    deleteDataFromServer(
      `/order?ids=${selectedIDs.filter((id) => id !== -1).join(",")}`
    )
      .then((response) => {
        const newData = rows.filter((data) => !selectedIDs.includes(data.id));
        setRows([...newData]);
        alert("데이터가 삭제되었습니다.");
      })
      .catch((error) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  // ACI 운송장변환
  const handleExportACIExcel = async () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }
    setProcessing(true);
    // 운송장 변환에 필요한 데이터 가져오기
    const response = await getDataFromServer(
      `/order/aci?ids=${selectedIDs.toString()}`
    );

    if (response.status !== 200) {
      alert("운송장 변환에 실패했습니다.");
      setProcessing(false);
      return;
    }

    // 운송장 변환 후 다운로드
    const ACIColumns = [
      { ko: "주문번호", en: "order_uid" },
      { ko: "수취인", en: "recipient_name" },
      { ko: "Brand", en: "" },
      { ko: "상품명", en: "en_name" },
      { ko: "Unit Value", en: "unit_value" },
      { ko: "상품수량", en: "quantity" },
      { ko: "Sub Total Value", en: "sub_total_value" },
      { ko: "HS CODE", en: "hscode" },
      { ko: "수취인 TEL", en: "phone_number_tel" },
      { ko: "수취인 HP", en: "phone_number_hp" },
      { ko: "개인통관식별번호", en: "customs_number" },
      { ko: "우편번호", en: "zipcode" },
      { ko: "주소", en: "main_address" },
      { ko: "나머지 주소", en: "detail_address" },
      { ko: "실무게", en: "actual_weight" },
      { ko: "부피무게", en: "volume_weight" },
      { ko: "포장 박스수량", en: "package_count" },
      { ko: "상거래유형", en: "commercial_type" },
      { ko: "용도구분", en: "usage_classification" },
      { ko: "구입사이트", en: "site_url" },
      { ko: "배송메모", en: "delivery_message" },
    ];

    const newData = response.result.map((data) => {
      const newPhoneNumber = data.phone_number.includes("-")
        ? data.phone_number
        : data.phone_number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      data.commercial_type = "B";
      data.phone_number_tel = newPhoneNumber;
      data.phone_number_hp = newPhoneNumber;
      data.package = data.package_count;
      const unit_value = (data.price / data.dollar).toFixed(2);
      data.unit_value = unit_value;
      data.sub_total_value = (unit_value * data.quantity).toFixed(2);
      data.en_name = data.en_name
        ? data.en_name
        : `${data.brand ? data.brand.replace(/[가-힣]+/, "").trim() : ""} ${
            data.item_name
          } ${
            data.option
              ? data.option
                  .replace("컬러 :", "")
                  .replace("사이즈 :", "")
                  .replace(" / ", "")
              : ""
          }`;
      return data;
    });

    createShipmentExcel(ACIColumns, newData, "ACI_excel");

    setSelectedIDs([]);
    setProcessing(false);
  };

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      {/* 조회조건 필터 */}
      <Box height="150px" display="flex" width="100%">
        <Box border="1px solid" width="100%" sx={{ overflow: "auto" }}>
          <Box display="flex">
            {/* 주문번호 */}
            <Box
              sx={{ minWidth: 300 }}
              display="flex"
              alignItems="center"
              margin="0.5rem 0"
            >
              <Typography margin="0 1rem">주문번호 :</Typography>
              <TextField
                variant="outlined"
                name="주문번호"
                value={orderUid}
                onChange={handleChangeOrderUid}
              />
            </Box>
            {/* 수취인명 */}
            <Box
              sx={{ minWidth: 300 }}
              display="flex"
              alignItems="center"
              margin="0.5rem 0"
            >
              <Typography margin="0 1rem">수취인명 :</Typography>
              <TextField
                variant="outlined"
                name="수취인명"
                value={recipientName}
                onChange={handleChangeRecipientName}
              />
            </Box>
            {/* 업로드일 */}
            <Box
              sx={{ minWidth: 300 }}
              display="flex"
              alignItems="center"
              margin="0.5rem 0.5rem"
            >
              <Typography margin="0 1rem">업로드일 :</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    label="업로드일"
                    inputFormat="YYYY-MM-DD"
                    value={uploadDate}
                    onChange={(newValue) => setUploadDate(newValue)}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>
          <Box
            onClick={handleSearchFilteredData}
            width="300px"
            sx={{ backgroundColor: "darkblue", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem"
          >
            <Typography variant="subtitle2" color="white">
              검색
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography margin="1rem 0" color={primary} fontWeight="bold">
        * 로그인된 아이디로 업로드한 데이터만 볼 수 있습니다. / 최초 데이터는
        3일간 업로드된 데이터만 표시됩니다.
      </Typography>
      {/* 주문데이터 테이블 */}
      <DataTable
        columns={columns}
        selectedIDs={selectedIDs}
        rows={rows}
        handleClickCheckBox={handleClickCheckBox}
        handleInputInfoChange={handleInputInfoChange}
      />

      {/* 수정, 변환 버튼 */}
      <Box display="flex">
        <Box
          onClick={handleDeleteData}
          width="300px"
          sx={{ backgroundColor: "red", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            데이터삭제
          </Typography>
        </Box>
        <Box
          onClick={handleUpdateData}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            데이터수정
          </Typography>
        </Box>
        <Box
          onClick={handleExportACIExcel}
          width="300px"
          sx={{ backgroundColor: "orangered", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            운송장변환(ACI)
          </Typography>
        </Box>

        <Box
          // onClick={handleSubmit}
          width="300px"
          sx={{ backgroundColor: "darkblue", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            운송장변환(판토스)
          </Typography>
        </Box>
      </Box>
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default Search;
