import { Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteDataFromServer,
  getDataFromServer,
} from "../common/network/network";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../common/style/styles";
import DataTable from "../components/data_table";

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "스토어명", en: "name", width: "70px", type: "text" },
  { ko: "고객사명", en: "company_name", width: "70px", type: "text" },
  { ko: "보기", en: "show", width: "50px", type: "page" },
  { ko: "수정", en: "edit", width: "50px", type: "page" },
];

// 스토어 정보관리
const StoreList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  useEffect(() => {
    getDataFromServer("/store").then((response) => {
      setRows([...response.data]);
    });
  }, []);

  const handleClickButton = (mode, id) => {
    navigate(`/store/${mode}/${id}`);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  const handleDelete = () => {
    if (!selectedIDs.length) {
      alert("데이터를 선택해주세요.");
      return;
    }
    if (!window.confirm("선택한 데이터를 삭제하시겠습니까?")) {
      return;
    }

    setProcessing(true);

    deleteDataFromServer(
      `/store?ids=${selectedIDs.filter((id) => id !== -1).join(",")}`
    )
      .then((response) => {
        const newData = rows.filter((data) => !selectedIDs.includes(data.id));
        setRows([...newData]);
        alert("스토어 정보가 삭제되었습니다.");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <DataTable
        columns={columns}
        selectedIDs={selectedIDs}
        rows={rows}
        handleClickCheckBox={handleClickCheckBox}
        // handleInputInfoChange={handleInputInfoChange}
        handleClickButton={handleClickButton}
      />
      <Box display="flex">
        <Box
          onClick={() => {
            navigate("/store/create/-1");
          }}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            스토어 추가
          </Typography>
        </Box>
        <Box
          onClick={handleDelete}
          width="300px"
          sx={{ backgroundColor: "red", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            스토어 삭제
          </Typography>
        </Box>
      </Box>
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default StoreList;
