import { Box, Button, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { postDataFromServer } from "../common/network/network";
import { IsAdminState } from "../common/state/state";
import { lightgrey, StyledLink } from "../common/style/styles";

const Login = () => {
  const [loginInfo, setLoginInfo] = useState({ email: "", pw: "" });
  const [isAdmin, setIsAdmin] = useRecoilState(IsAdminState);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  //   로그인을 한 상태일 경우 홈으로 리디렉션
  useEffect(() => {
    if (isAdmin) {
      navigate("/upload");
    }
  }, []);

  const handleSubmit = () => {
    postDataFromServer("/login", loginInfo)
      .then((response) => {
        navigate("/upload");
      })
      .catch((error) => {
        if (error.response.status == 401 || error.response.status == 403) {
          alert("아이디 또는 비밀번호를 확인해주세요.");
          return;
        }
      });
  };

  return (
    <Box
      margin="8px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width="600px"
        display="flex"
        border="1px solid"
        flexDirection="column"
        margin="128px 0"
      >
        <Typography
          variant="subtitle2"
          component="p"
          padding="8px 16px"
          sx={{ backgroundColor: "black", color: "white" }}
        >
          MPICK ERP 로그인
        </Typography>
        <Box margin="32px">
          <Box
            display="flex"
            justifyContent="space-between"
            margin="8px"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              component="p"
              margin="0 28px 0 0"
              minWidth="max-content"
            >
              이메일
            </Typography>
            <TextField
              label="이메일"
              variant="outlined"
              fullWidth={true}
              name="email"
              value={loginInfo.email}
              onChange={handleInputChange}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            margin="32px 8px"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              component="p"
              margin="0 16px 0 0"
              minWidth="max-content"
            >
              비밀번호
            </Typography>
            <TextField
              type="password"
              label="비밀번호"
              variant="outlined"
              fullWidth={true}
              name="pw"
              value={loginInfo.pw}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box
          backgroundColor={lightgrey}
          padding="16px 32px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            sx={{ color: "white", padding: "8px 64px" }}
            onClick={handleSubmit}
          >
            <Typography variant="subtitle1" component="p">
              로그인
            </Typography>
          </Button>
          <StyledLink to="/admin/login/password">
            <Typography
              variant="subtitle2"
              component="p"
              sx={{ textDecoration: "underline" }}
            >
              비밀번호를 잊으셨나요?
            </Typography>
          </StyledLink>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
