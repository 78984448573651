import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getDataFromServer } from "../common/network/network";
import { IsAdminState } from "../common/state/state";
import { lightgrey } from "../common/style/styles";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [adminEmail, setAdminEamil] = useState("");
  const [isAdmin, setIsAdmin] = useRecoilState(IsAdminState);

  // 헤더에 표시할 어드민 유저의 이메일 가져오기
  useEffect(() => {
    getDataFromServer("/users/email")
      .then((response) => {
        console.log(response);
        setAdminEamil(response.user_email);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          alert("로그인 후 사용해주세요.");
          navigate("/login");
        }
      });
  }, []);

  // 로그아웃
  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      getDataFromServer("/logout");
      navigate("/login");
      setIsAdmin(false);
    }
  };
  return (
    <Box position="fixed" top="0" left="0" width="100%" zIndex="110">
      <Box
        padding="4px 8px"
        display="flex"
        justifyContent="space-between"
        sx={{ backgroundColor: lightgrey }}
      >
        <Typography variant="subtitle1" component="p" gutterBottom>
          MPICK ERP
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" />
          <Typography variant="h6" component="p" gutterBottom padding="0 8px">
            [{adminEmail}]
          </Typography>
          <Divider orientation="vertical" />
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            padding="0 8px"
            onClick={handleLogout}
            sx={{ cursor: "pointer" }}
          >
            로그아웃
          </Typography>
        </Box>
      </Box>
      <Box padding="4px 8px" sx={{ backgroundColor: "white" }}>
        <Typography variant="h6" component="p" gutterBottom>
          {pathname.includes("users") ? "사용자 관리" : null}
          {pathname.includes("upload") ? "주문데이터 업로드" : null}
          {pathname.includes("orders") ? "주문데이터 조회" : null}
          {pathname.includes("shipment") ? "송장데이터 업로드(ACI)" : null}
          {pathname.includes("customs-duty") ? "세관신고서 작성" : null}
          {pathname.includes("invoice") ? "고객사 인보이스 생성" : null}
          {pathname.includes("store") ? "스토어 정보관리" : null}
          {pathname.includes("billing-company") ? "청구회사 관리" : null}
          {pathname.includes("client") ? "고객사 관리" : null}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export default Header;
