import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import {
  getDataFromServer,
  postDataFromServer,
} from "../common/network/network";
import { primary } from "../common/style/styles.js";
import { convertDate, createInvoiceExcel } from "../common/js/common";

// 고객사 인보이스 생성
const Invoice = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const [billingCompanyList, setBillingCompanyList] = useState([]);
  const [clientCompanyList, setClientCompanyList] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [processing, setProcessing] = useState(false);
  const [image, setImage] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInvoice({ ...invoice, [name]: value });
  };

  useEffect(() => {
    getDataFromServer("/client").then((response) => {
      setClientCompanyList([...response.data]);
    });

    getDataFromServer("/billing-company").then((response) => {
      setBillingCompanyList([...response.data]);
    });

    getDataFromServer("/invoice/client").then((response) => {
      console.log(response);
      setInvoice({
        consignee_name: response.result.consignee_name,
        consignee_address: response.result.consignee_address,
      });
    });

    fetch(
      "https://mpick.s3.ap-northeast-2.amazonaws.com/mpick/admin/millionpick_logo.png"
    )
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((buffer) => {
        // console.log(buffer);
        setImage(buffer);
      });
  }, []);

  const handleDownlaod = () => {
    if (
      Object.keys(invoice).length < 5 ||
      !invoiceDate ||
      !startDate ||
      !endDate
    ) {
      alert("인보이스 정보를 모두 입력해주세요.");
      return;
    }
    setProcessing(true);

    //출항일자에 해당하는 세관신고서 정보 가져오기
    const selectClient = clientCompanyList.find(
      (company) => (company.id = invoice.client_company_id)
    );
    const selectBillingCompany = billingCompanyList.find(
      (company) => (company.id = invoice.billing_company_id)
    );

    getDataFromServer(
      `/customs-duty/invoice?start_date=${convertDate(
        new Date(startDate),
        1
      )}&end_date=${convertDate(new Date(endDate), 1)}&country=${
        selectBillingCompany["country"]
      }&billing_company_id=${selectBillingCompany["id"]}&client_company_id=${
        selectClient["id"]
      }`
    )
      .then((response) => {
        // console.log(response);

        const downloadresult = createInvoiceExcel(
          response.result,
          invoiceDate,
          invoice.number,
          image,
          response.billing_company_info,
          response.client_company_info,
          response.shipment_date_list,
          invoice.consignee_name,
          invoice.consignee_address,
          invoice.number
        );
      })
      .finally(() => {
        postDataFromServer("/invoice/client", {
          base_price: invoice.base_price,
          exchage_rate: invoice.exchage_rate,
          consignee_name: invoice.consignee_name,
          consignee_address: invoice.consignee_address,
        });

        setProcessing(false);
      });
  };

  return (
    <Box margin="2rem 1rem" sx={{ overflow: "auto" }}>
      <Box>
        <Box>
          {/* 청구회사 */}
          <Box
            sx={{ minWidth: 300 }}
            display="flex"
            alignItems="center"
            margin="0.5rem 0"
            justifyContent="le"
          >
            <Typography margin="0 1rem" textAlign="left" minWidth="100px">
              청구회사 :
            </Typography>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel>청구회사</InputLabel>

              <Select
                name="billing_company_id"
                value={
                  invoice.billing_company_id ? invoice.billing_company_id : null
                }
                onChange={handleInputChange}
              >
                {billingCompanyList.map((company) => (
                  <MenuItem value={company.id}>{company.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* 출항일자 */}
          <Box
            sx={{ minWidth: 300 }}
            display="flex"
            alignItems="center"
            margin="0.5rem 0"
            // justifyContent="space-evenly"
          >
            <Typography margin="0 1rem" textAlign="left" minWidth="100px">
              출항일자 :
            </Typography>

            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    label="시작일"
                    format="YYYY-MM-DD"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  label="종료일"
                  format="YYYY-MM-DD"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          {/* 고객사 */}
          <Box display="flex" alignItems="center" margin="0.5rem 0">
            <Typography margin="0 1rem" textAlign="left" minWidth="100px">
              고객사 :
            </Typography>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel>고객사</InputLabel>

              <Select
                name="client_company_id"
                value={
                  invoice.client_company_id ? invoice.client_company_id : null
                }
                onChange={handleInputChange}
              >
                {clientCompanyList.map((company) => (
                  <MenuItem value={company.id}>{company.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* 인보이스 발행일 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" minWidth="100px" textAlign="left">
            인보이스 발행일 :
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                label="발행일"
                format="DD-MM-YYYY"
                value={invoiceDate}
                onChange={(newValue) => setInvoiceDate(newValue)}
              />
            </Stack>
          </LocalizationProvider>
        </Box>

        {/* 인보이스 넘버 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" minWidth="100px" textAlign="left">
            인보이스 넘버 :
          </Typography>
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="number"
            value={invoice.number}
            onChange={handleInputChange}
          />
        </Box>

        {/* 정산기준금액 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" minWidth="100px" textAlign="left">
            정산기준금액 :
          </Typography>
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="base_price"
            value={invoice.base_price}
            onChange={handleInputChange}
          />
        </Box>

        {/* 기준환율 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" textAlign="left" minWidth="100px">
            기준환율 :
          </Typography>
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="exchage_rate"
            value={invoice.exchage_rate}
            onChange={handleInputChange}
          />
        </Box>

        {/* Consignee 이름 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" textAlign="left" minWidth="100px">
            Consignee 이름 :
          </Typography>
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="consignee_name"
            value={invoice.consignee_name}
            onChange={handleInputChange}
          />
        </Box>

        {/* Consignee 주소 */}
        <Box display="flex" alignItems="center" margin="0.5rem 0">
          <Typography margin="0 1rem" textAlign="left" minWidth="100px">
            Consignee 주소 :
          </Typography>
          <TextField
            sx={{ width: "50%" }}
            variant="outlined"
            name="consignee_address"
            value={invoice.consignee_address}
            onChange={handleInputChange}
          />
        </Box>

        {/* 인보이스 생성 */}
        <Box
          onClick={handleDownlaod}
          width="300px"
          sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
          padding="0.5rem 0"
          margin="1rem"
        >
          <Typography variant="subtitle1" color="white">
            인보이스 생성
          </Typography>
        </Box>
      </Box>
      <Divider />
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default Invoice;
