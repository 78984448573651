import {
  FormControl,
  MenuItem,
  Box,
  Divider,
  Typography,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getDataFromServer,
  postDataFromServer,
} from "../common/network/network";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../common/style/styles";

// 주문정보 업로드 페이지
const OrderUpload = () => {
  const [channel, setChannel] = useState(null);
  const [store, setStore] = useState(null);
  const [country, setCountry] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [dollar, setDollar] = useState(null);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 채널 변경
  const handleChangeChannel = (event) => {
    setChannel(event.target.value);
  };
  // 스토어 변경
  const handleChangeStore = (event) => {
    setStore(event.target.value);
  };
  // 국가 변경
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  // 달러변환율 변경
  const handleDollarChange = (event) => {
    const { name, value } = event.target;
    setDollar(value);
  };

  // 엑셀 첨부
  const handleExcelFile = (event) => {
    if (!event.target.files) {
      return;
    }
    setExcelFile([...event.target.files]);
  };

  // 엑셀 업로드
  const handleSubmit = () => {
    if (!excelFile) {
      alert("파일을 첨부해주세요.");
      return;
    }

    if (!channel) {
      alert("채널명을 선택해주세요.");
      return;
    }

    if (!store) {
      alert("스토어명을 선택해주세요.");
      return;
    }

    if (!dollar) {
      alert("환율을 입력해주세요.");
      return;
    }

    if (processing) {
      return;
    }

    setProcessing(true);
    const formData = new FormData();

    let fileNames = [];

    for (const file of excelFile) {
      formData.append(file.name, file);
      fileNames.push(file.name);
    }

    formData.append("channel", channel);
    formData.append(
      "store_id",
      storeList.find((info) => info.name === store).id
    );
    formData.append("country", country);
    formData.append("fileNames", JSON.stringify(fileNames));
    formData.append("dollar", dollar);

    postDataFromServer("/order", formData)
      .then((response) => {
        if (response.error_user_list.length > 0) {
          alert(
            `${response.error_user_list.join(
              ","
            )} 제외 주문데이터 업로드가 완료되었습니다.`
          );
        } else {
          alert("주문데이터 업로드가 완료되었습니다.");
        }

        window.location.reload();
      })
      .catch((error) => {
        alert("업로드에 실패했습니다. 엑셀을 확인해주세요.");
        window.location.reload();
      })
      .finally(() => {
        setProcessing(false);
        setExcelFile(null);
        setChannel(null);
        setCountry(null);
        setStore(null);
      });
  };

  useEffect(() => {
    getDataFromServer("/order/info")
      .then((response) => {
        setCountry(response.country.country);
        setStoreList(response.store_list);
        setDollar(response.dollar.price);
      })
      .catch((error) => {});
  }, []);

  return (
    <Box margin="2rem 1rem">
      <Box display="flex">
        {/* 판매채널 선택 드롭다운 */}
        <Box
          sx={{ minWidth: 150 }}
          display="flex"
          alignItems="center"
          margin="1rem 0"
        >
          <Typography variant="subtitle2" margin="0 1rem">
            판매채널 :
          </Typography>

          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>판매채널</InputLabel>
            <Select value={channel} onChange={handleChangeChannel}>
              <MenuItem value="스마트스토어">스마트스토어</MenuItem>
              <MenuItem value="자사몰">자사몰</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* 스토어명 선택 드롭다운 */}
        {/* 밀리언픽, 파리부티크, 미스프리베, 밀리언픽 스페인, 파리앙쥬, 몽파리 */}
        <Box
          sx={{ minWidth: 150 }}
          display="flex"
          alignItems="center"
          margin="1rem 0"
        >
          <Typography variant="subtitle2" margin="0 1rem">
            스토어명 :
          </Typography>

          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>스토어명</InputLabel>
            <Select value={store} onChange={handleChangeStore}>
              {storeList &&
                storeList.map((info) => (
                  <MenuItem value={info.name}>{info.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        {/* 국가선택 드롭다운 */}
        <Box
          sx={{ minWidth: 100 }}
          display="flex"
          alignItems="center"
          margin="1rem 0"
        >
          <Typography variant="subtitle2" margin="0 1rem">
            출고 국가 :
          </Typography>

          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>출고 국가</InputLabel>
            {country ? (
              <Select value={country} onChange={handleChangeCountry}>
                <MenuItem value="스페인">스페인</MenuItem>
                <MenuItem value="프랑스">프랑스</MenuItem>
              </Select>
            ) : null}
          </FormControl>
        </Box>

        {/* 운송장 변환환율 */}
        <Box
          sx={{ minWidth: 300 }}
          display="flex"
          alignItems="center"
          margin="0.5rem 0"
        >
          <Typography margin="0 1rem" minWidth="fit-content">
            운송장 변환환율 :
          </Typography>
          <TextField
            variant="outlined"
            name="달러"
            value={dollar}
            onChange={handleDollarChange}
          />
        </Box>
      </Box>

      <Box width="80%">
        <Box margin="16px 8px">
          <Divider />
          <Box margin="1rem 0" padding="0.5rem" border={"1px dashed"}>
            <label htmlFor="excel">
              <Typography margin="1rem 0" color={primary} fontWeight="bold">
                * 네이버 스마트스토어 엑셀 업로드시 비밀번호만 없는 상태 그대로
                올려주세요.
              </Typography>
              <Typography margin="0 4px">
                발송처리할 파일을 선택해주세요. (.xlsx 확장자만 첨부 가능)
              </Typography>
            </label>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="excel"
              id="excel"
              multiple
              onChange={handleExcelFile}
            />
          </Box>
          {excelFile && (
            <Box margin="1rem 0" padding="0.5rem" border={"1px solid"}>
              <Typography>선택된 파일 : </Typography>
              {excelFile.map((excel) => (
                <Typography>{excel.name}</Typography>
              ))}
            </Box>
          )}
          <Box
            onClick={handleSubmit}
            sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
            padding="0.5rem 0"
            margin="1rem 0"
          >
            <Typography variant="subtitle1" color="white">
              업로드
            </Typography>
          </Box>
        </Box>
      </Box>
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={processing}
      />
    </Box>
  );
};

export default OrderUpload;
