import React from "react";
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const DataTable = ({
  columns,
  rows,
  selectedIDs,
  handleClickCheckBox,
  handleInputInfoChange,
  handleClickButton,
}) => {
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        minHeight: (window.innerHeight * 7) / 13,
      }}
    >
      <TableContainer
        sx={{
          maxHeight: (window.innerHeight * 7) / 13,
          minHeight: (window.innerHeight * 7) / 13,
          padding: "0.5rem 0",
        }}
      >
        <Table stickyHeader width="100%" height="100%" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell style={{ minWidth: column.width, padding: "0 4px" }}>
                  {column.type === "checkbox" ? (
                    <Checkbox
                      checked={selectedIDs.includes(-1) ? true : false}
                      onChange={() => handleClickCheckBox(-1)}
                    />
                  ) : (
                    <Typography variant="body2">{column.ko}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <TableCell sx={{ padding: "0 4px" }}>
                    {column.type === "checkbox" ? (
                      <Checkbox
                        checked={selectedIDs.includes(row.id) ? true : false}
                        onChange={() => handleClickCheckBox(row.id)}
                      />
                    ) : column.type === "text" ? (
                      <Typography variant="body2">
                        {row[column.en] ? row[column.en] : ""}
                      </Typography>
                    ) : column.type === "input" ? (
                      <TextField
                        style={{ minWidth: column.width }}
                        inputProps={{
                          style: { fontSize: "10px" },
                        }}
                        variant="standard"
                        name={column.en}
                        value={
                          row[column.en]
                            ? row[column.en]
                            : column.default
                            ? column.default
                            : ""
                        }
                        size="small"
                        onChange={(event) => {
                          handleInputInfoChange(event, row.id);
                        }}
                      />
                    ) : column.type === "button" && row[column.en] ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleClickButton(row.id);
                        }}
                      >
                        다운로드
                      </Button>
                    ) : column.type === "page" ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleClickButton(column.en, row.id);
                        }}
                      >
                        {column.ko}
                      </Button>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
