import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromServer } from "../common/network/network";
import DataTable from "../components/data_table";

const columns = [
  { ko: "checkbox", en: "checkbox", width: "30px", type: "checkbox" },
  { ko: "id", en: "id", width: "30px", type: "text" },
  { ko: "이메일", en: "email", width: "70px", type: "text" },
  { ko: "국가", en: "country", width: "50px", type: "text" },
  { ko: "탈퇴여부", en: "withdrawal", width: "50px", type: "text" },
];

// 사용자관리 ( 유저타입=0만 접근가능)
const User = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  const handleDeleteUser = () => {};

  // 사용자 권한 확인 , 사용자 리스트 가져오기
  useEffect(() => {
    getDataFromServer("/users")
      .then((response) => {
        if (response.user_info.user_type !== 0) {
          alert("관리자만 접근 가능한 메뉴입니다.");
          navigate("/upload");
        } else {
          setIsAdmin(response.user_info.user_type !== 0 ? false : true);
          setRows([...response.result]);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <Box margin="2rem 1rem">
      {isAdmin ? (
        <Box>
          {/* 사용자 테이블 */}
          <DataTable
            columns={columns}
            selectedIDs={selectedIDs}
            rows={rows}
            handleClickCheckBox={handleClickCheckBox}
            // handleInputInfoChange={handleInputInfoChange}
          />
          <Box display="flex">
            <Box
              flex="1"
              onClick={handleDeleteUser}
              sx={{ backgroundColor: "orangered", cursor: "pointer" }}
              padding="0.5rem 0"
              margin="1rem 0"
            >
              <Typography variant="subtitle1" color="white">
                탈퇴 처리
              </Typography>
            </Box>
            <Box
              flex="1"
              onClick={() => {
                navigate("/join");
              }}
              sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
              padding="0.5rem 0"
              margin="1rem 0"
            >
              <Typography variant="subtitle1" color="white">
                사용자 생성
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default User;
