import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postDataFromServer } from "../common/network/network";

// 사용자 생성
const Join = () => {
  const [userInfo, setUserInfo] = useState({ email: "", pw: "", country: "" });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSunbmit = () => {
    if (!userInfo.email) {
      alert("이메일을 입력해주세요.");
      return;
    }

    if (!userInfo.pw) {
      alert("비밀번호를 입력해주세요.");
      return;
    }

    if (!userInfo.country) {
      alert("국가를 입력해주세요.");
      return;
    }

    postDataFromServer("/users", userInfo)
      .then((response) => {
        alert("사용자가 생성되었습니다.");
        navigate("/users");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          alert("중복되는 이메일입니다.");
          return;
        }
      });
  };

  return (
    <Box Box margin="2rem 3rem">
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          사용자 이메일 :
        </Typography>
        <TextField
          sx={{ width: "50%" }}
          variant="outlined"
          name="email"
          value={userInfo.email}
          onChange={handleInputChange}
        />
      </Box>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          사용자 비밀번호 :
        </Typography>
        <TextField
          sx={{ width: "50%" }}
          variant="outlined"
          name="pw"
          value={userInfo.pw}
          onChange={handleInputChange}
        />
      </Box>
      <Box display="flex" alignItems="center" margin="0.5rem 0">
        <Typography width="100px" margin="0 1rem" textAlign="left">
          업로드 국가 :
        </Typography>
        <TextField
          sx={{ width: "50%" }}
          variant="outlined"
          name="country"
          value={userInfo.country}
          onChange={handleInputChange}
        />
      </Box>
      <Box
        width="50%"
        onClick={handleSunbmit}
        sx={{ backgroundColor: "forestgreen", cursor: "pointer" }}
        padding="0.5rem 0"
        margin="2rem 3rem"
      >
        <Typography variant="subtitle1" color="white">
          사용자 생성
        </Typography>
      </Box>
    </Box>
  );
};

export default Join;
